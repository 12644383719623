import '../Styles/Footer.css';

const Footer = () => {
    return (
        <footer>
            <p>Made with ❤️ by Aldo Saman</p>
        </footer>
    )
}

export default Footer;